<template>
    <section class="footer">
        <div>
            <div class="d-flex justify-center">
                <v-btn @click="sendEmail" color="grey" fab class="footer__submit mb-10 pa-10" elevation="0">submit</v-btn>
            </div>
            <p class="text-center mt-2 mx-2">© 2O22  copyright dgFactor Network Technology CO., LTD  All rights reserved.</p>
        </div>
    </section>
</template>

<script>
export default {
    methods: {
        sendEmail() {
            this.$root.$emit('sendEmail')
        },
    },
}
</script>
