<template>
  <section class="talk-section">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="talk-info d-flex flex-column flex-md-row justify-space-between align-md-center mb-10">
            <p class="talk-info__title white--text">Let's Talk</p>
            <div class="mb-5">
              <p class="white--text mb-2">
                220新北市板橋區四川路一段23號9號樓之2
              </p>
              <p>dgFactor Network Technology CO., LTD</p>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-form v-model="formValid">
            <v-row>
              <v-col cols="12">
                <label for="" class="d-block mb-5">
                  <span class="green--text mr-3"><span class="green--text mr-1">*</span>01</span>
                  <span class="white--text">Company 公司</span>
                </label>
                <v-text-field
                  v-model="from_company"
                  :rules="baseRules"
                  dark
                  dense
                  class="mb-10 talk-input"
                  placeholder="Type your company name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="" class="d-block mb-5">
                  <span class="green--text mr-3"><span class="green--text mr-1">*</span>02</span>
                  <span class="white--text">Name 姓名</span>
                </label>
                <v-text-field
                  v-model="from_name"
                  :rules="baseRules"
                  dark
                  dense
                  class="mb-10 talk-input"
                  placeholder="Type your name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="" class="d-block mb-5">
                  <span class="green--text mr-3"><span class="green--text mr-1">*</span>03</span>
                  <span class="white--text">Mobile 電話</span>
                </label>
                <v-text-field
                  v-model="phone"
                  :rules="baseRules"
                  dark
                  dense
                  class="mb-10 talk-input"
                  placeholder="Type your mobile"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label for="" class="d-block mb-5">
                  <span class="green--text mr-3"><span class="green--text mr-1">*</span>04</span>
                  <span class="white--text">Email 信箱</span>
                </label>
                <v-text-field
                  v-model="email"
                  :rules="baseRules"
                  dark
                  dense
                  class="mb-10 talk-input"
                  placeholder="Type your Email"
                ></v-text-field>
              </v-col>
              <v-col>
                <label for="" class="d-block mb-5">
                  <span class="green--text mr-3"><span class="green--text mr-1">*</span>05</span>
                  <span class="white--text">Your Message 內容</span>
                </label>
                <v-textarea
                  v-model="content"
                  :rules="baseRules"
                  dark
                  dense
                  rounded-0
                  class="mb-1 talk-input"
                  placeholder="Type your message"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import emailjs from "@/components/email/cjs/index.js";
export default {
  data: () => ({
    formValid: false,
    from_company: null,
    from_name: null,
    email: null,
    phone: null,
    content: null,
  }),
  computed: {
    baseRules() {
      return [(value) => this.$helper.formRules(this).requiredRule(value)];
    },
  },
  created() {
    this.onEvent();
  },
  methods: {
    onEvent() {
      this.$root.$on("sendEmail", this.sendEmail);
      this.$once("hook:beforeDestroy", () => {
        this.$root.$off("sendEmail", this.sendEmail);
      });
    },
    async sendEmail() {
      if (!this.formValid) {
        this.$snotify.error(null, "請檢查欄位是否正確");
        return;
      }

      this.$store.dispatch(`base/loading`);
      let templateParams = {
        from_company: this.from_company,
        from_name: this.from_name,
        email: this.email,
        phone: this.phone,
        content: this.content,
        // 'g-recaptcha-response': "6Ler_4ggAAAAAAyKoxbwt3LFysEqHMtJrTlyt71d"
      };

      try {
        const res = await emailjs.send(
          "service_svmau1h",
          "template_nknx6ll",
          templateParams,
          "itfbp7HcSRrtm592d"
        );
        console.log("SUCCESS!", res.status, res.text);
        this.$snotify.success(null, "已寄出信件");
      } catch (err) {
        console.log("FAILED...", err);
        this.$snotify.error(null, "發生錯誤,請稍後再試");
      } finally {
        this.$store.dispatch(`base/loading`, false);
      }
    },
  },
};
</script>